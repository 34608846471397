@import "~antd/dist/antd.css";
@import "./style/iconfont.css";

@font-face {
  font-family: "AlibabaPuHuiTi-Bold";
  font-weight: 700;
  src: url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/f1JGz-ZgzimRsizqQLchi.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/i9ZxCkVhX6aTwrKr8UHM-.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "AlibabaPuHuiTi-Medium";
  font-weight: 500;
  src: url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/03V6etM_0ioFSTgfglH56.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/S8QahhDDhMJ8LRKLab9Jw.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "AlibabaPuHuiTi";
  font-weight: 400;
  src: url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/YOWA0M5ZHmAstJ4vEpOoP.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/L9myayhWEjtc/gEAUG3pcp-gq73SKRQaRN.woff")
      format("woff");
  font-display: swap;
}

.AlibabaPuHuiTi {
  font-family: "AlibabaPuHuiTi" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AlibabaPuHuiTi-Bold {
  font-family: "AlibabaPuHuiTi-Bold" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AlibabaPuHuiTi-Medium {
  font-family: "AlibabaPuHuiTi-Medium" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-flex-1 {
  flex: 1;
}
