.bcblue {
  background: -webkit-linear-gradient(
    left,
    #004bff,
    #015cff,
    #0357fe,
    #0574ff,
    #2b59ff,
    #474cff
  );
  padding: 60px 0;
}

.guan1200 {
  width: 1200px;
  margin: 0 auto;
}

.dinoab {
  display: none;
}

.padd {
  padding: 40px 0;
}

// 首
.guan1200 .guan_font {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
}

.guan1200 .guan_min {
  color: #fff;
  font-size: 18px;
}

// 企业文化
.guan1200 .qi_font {
  font-size: 36px;
  color: #040816;
  text-align: center;
}

.guan1200 .qi_min {
  text-align: center;
  color: #7c7d81;
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
}

.w42 {
  width: 450px;
  margin: 0 auto;
}

.guan1200 .qi_san {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 77px;
}

.guan1200 .qi_san .qi_on {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.guan1200 .qi_san .qi_on .sanimg {
  width: 100px;
  height: 100px;
}

.guan1200 .qi_san .qi_on .qi_na {
  color: #040816;
  font-size: 22px;
  margin: 8px 0;
}

.guan1200 .qi_san .qi_on .qi_va {
  color: #888888;
  font-size: 18px;
}

// 管理团队
.bcfb {
  background-color: #f6f8fb;
}

.guan1200 .tuan_zhan {
  width: 100%;
  height: auto;
  background-image: url(../../image/zubei.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
}

.guan1200 .tuan_zhan .tuan_zhan_left {
  width: 70%;
}

.guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_name {
  color: #fff;
  font-size: 34px;
  font-weight: bold;
}

.guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_jie {
  width: 276px;
  color: #fff;
  font-size: 20px;
  margin: 12px 0;
  background: -webkit-linear-gradient(left, #2dd8ff, rgba(22, 125, 249, 0));
  padding: 7px 16px;
}

.guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_co {
  color: #fff;
  width: 550px;
  font-size: 18px;
}

.guan1200 .tuan_zhan .tuan_zhan_right {
  width: 330px;
  height: 340px;
}

.guan1200 .tuan_fen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}

.guan1200 .tuan_fen .tuan_fen_o {
  width: 48%;
  padding: 40px;
  // border: 1px solid #707070;
  min-height: 100px;
  border-radius: 8px;
  background-image: url(../../image/about.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.guan1200 .tuan_fen .tuan_fen_o .tuan_fen_name {
  color: #000000;
  font-size: 34px;
  font-weight: bold;
}

.guan1200 .tuan_fen .tuan_fen_o .tuan_fen_po {
  width: 276px;
  padding: 7px 16px;
  background: -webkit-linear-gradient(left, #167df9, rgba(45, 216, 255, 0));
  margin: 10px 0;
  color: #fff;
  font-size: 20px;
}

.guan1200 .tuan_fen .tuan_fen_o .tuan_fen_jie {
  color: #000000;
  font-size: 16px;
}

// 相关资质
.guan1200 .zi_list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
}

.guan1200 .zi_list .zi_o {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.guan1200 .zi_list .zi_o .zi_img {
  width: 240px;
  height: 160px;
}

.guan1200 .zi_list .zi_o .zi_img_route {
  transform: rotate(90deg);
  margin-bottom: 30px;
  margin-top: 24px;
}

.guan1200 .zi_list .zi_o .zi_name {
  color: #040816;
  font-size: 20px;
  margin-top: 20px;
}

// 公司地址
.guan1200 .map {
  width: 100%;
  height: 380px;
  margin-top: 50px;
}

.padda {
  padding: 20px 0 60px 0;
}

// 平板
@media screen and (max-width: 992px) {
  .bcblue {
    background: -webkit-linear-gradient(
      left,
      #004bff,
      #015cff,
      #0357fe,
      #0574ff,
      #2b59ff,
      #474cff
    );
    padding: 30px 0;
  }

  .guan1200 {
    width: 100%;
    padding: 0 30px;
  }

  .padd {
    padding: 20px 0;
  }

  // 首
  .guan1200 .guan_font {
    font-size: 25px;
  }

  .guan1200 .guan_min {
    font-size: 9px;
  }

  // 企业文化
  .guan1200 .qi_font {
    font-size: 18px;
  }

  .guan1200 .qi_min {
    font-size: 10px;
    width: 100%;
  }

  .guan1200 .qi_san {
    margin-top: 24px;
  }

  .guan1200 .qi_san .qi_on .sanimg {
    width: 50px;
    height: 50px;
  }

  .guan1200 .qi_san .qi_on .qi_na {
    font-size: 11px;
    margin: 4px 0;
  }

  .guan1200 .qi_san .qi_on .qi_va {
    font-size: 9px;
  }

  // 管理团队
  .bcfb {
    background-color: #f6f8fb;
  }

  .guan1200 .tuan_zhan {
    width: 100%;
    height: auto;
    background-color: #2979ff;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left {
    width: 60%;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_name {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_jie {
    width: 276px;
    color: #fff;
    font-size: 20px;
    margin: 12px 0;
    background: -webkit-linear-gradient(left, #2dd8ff, rgba(22, 125, 249, 0));
    padding: 7px 16px;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_co {
    color: #fff;
    font-size: 18px;
  }

  .guan1200 .tuan_zhan .tuan_zhan_right {
    width: 165px;
    height: 170px;
  }

  .guan1200 .tuan_fen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    padding: 30px;
  }

  .guan1200 .tuan_fen .tuan_fen_o {
    width: 48%;
    padding: 20px;
    min-height: 340px;
    border: 1px solid #707070;
    border-radius: 8px;
    background-image: url(../../image/about.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_name {
    color: #000000;
    font-size: 34px;
    font-weight: bold;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_po {
    width: 276px;
    padding: 7px 16px;
    background: -webkit-linear-gradient(left, #167df9, rgba(45, 216, 255, 0));
    margin: 10px 0;
    color: #fff;
    font-size: 20px;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_jie {
    color: #000000;
    font-size: 16px;
  }

  // 相关资质
  .guan1200 .zi_list {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .guan1200 .zi_list .zi_o {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .guan1200 .zi_list .zi_o .zi_img {
    width: 120px;
    height: 80px;
  }

  .guan1200 .zi_list .zi_o .zi_name {
    color: #040816;
    font-size: 10px;
    margin-top: 10px;
  }

  // 公司地址
  .guan1200 .map {
    width: 100%;
    height: 190px;
    margin-top: 25px;
  }

  .padda {
    padding: 0;
  }
}

// 手机
@media screen and (max-width: 600px) {
  .guan1200 {
    width: 100%;
  }

  .padd {
    padding: 20px 0;
  }

  // 首
  .guan1200 .guan_font {
    font-size: 25px;
  }

  .guan1200 .guan_min {
    font-size: 9px;
  }

  .guan1200 .qi_san {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    flex-direction: column;
  }

  // 管理团队
  .guan1200 .tuan_zhan {
    width: 100%;
    height: auto;
    background-color: #2979ff;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 12px;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left {
    width: 100%;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_name {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_jie {
    width: 138px;
    color: #fff;
    font-size: 10px;
    margin: 12px 0;
    background: -webkit-linear-gradient(left, #2dd8ff, rgba(22, 125, 249, 0));
    padding: 7px 8px;
  }

  .guan1200 .tuan_zhan .tuan_zhan_left .tuan_zhan_co {
    color: #fff;
    font-size: 18px;
    width: 100%;
  }

  .guan1200 .tuan_zhan .tuan_zhan_right {
    width: 165px;
    height: 170px;
  }

  .guan1200 .tuan_fen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 13px;
    padding: 30px;
  }

  .guan1200 .tuan_fen .tuan_fen_o {
    width: 100%;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 8px;
    margin-bottom: 20px;
    min-height: 200px;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_name {
    color: #000000;
    font-size: 17px;
    font-weight: bold;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_po {
    width: 138px;
    padding: 7px 8px;
    background: -webkit-linear-gradient(left, #167df9, rgba(45, 216, 255, 0));
    margin: 10px 0;
    color: #fff;
    font-size: 10px;
  }

  .guan1200 .tuan_fen .tuan_fen_o .tuan_fen_jie {
    color: #000000;
    font-size: 8px;
  }

  // 相关资质
  .guan1200 .zi_list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .guan1200 .zi_list .zi_o {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .guan1200 .zi_list .zi_o .zi_img {
    width: 120px;
    height: 80px;
  }

  .guan1200 .zi_list .zi_o .zi_name {
    color: #040816;
    font-size: 10px;
    margin-top: 10px;
  }

  // 公司地址
  .guan1200 .map {
    width: 100%;
    height: 200px;
    margin-top: 25px;
  }
}
