.swiper {
  width: 100%;
  height: auto;
  @media screen and (max-width: 992px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    height: auto;
  }
}

.swiper-slide {
  position: relative;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.imgobje {
  object-fit: cover;
}
