.taibc {
    background-color: #F6F8FB;
    padding: 40px 0;
}

.tai1200 {
    width: 1200px;
    margin: 0 auto;
}

.tai1200 .tai_ti {
    color: #040816;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
}

.tai1200 .tai_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}

.tai1200 .tai_flex .tai_box {
    width: 360px;
    background-color: #fff;
    margin-bottom: 30px;
    cursor: pointer;
}

.tai1200 .tai_flex .tai_box .tai_img {
    width: 100%;
    height: 200px;
}

.tai1200 .tai_flex .tai_box .tai_box_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 15px;
}

.tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao {
    padding: 0 16px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: rgba(41, 121, 255, .4);
    border-radius: 4px;
    color: #2979FF;
    font-size: 18px;
    font-weight: bold;
    margin: 14px;
}

.tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao:first-child {
    margin-left: 0;
}

.tai1200 .tai_flex .tai_box .tai_title {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin: 0 15px;
    margin-bottom: 15px;
}

.tai1200 .tai_flex .tai_box .tai_time {
    color: #777981;
    font-size: 16px;
    margin: 0 15px;
    text-align: right;
    padding-bottom: 16px;
}

.tai1200 .tai_auto{
    display: flex;
    align-items: center;
    justify-content: center;
}

// 平板
@media screen and (max-width:992px) {
    .taibc {
        background-color: #F6F8FB;
        padding: 20px;
    }
    
    .tai1200 {
        width: 100%;
        margin: 0 auto;
    }
    
    .tai1200 .tai_ti {
        color: #040816;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .tai1200 .tai_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
    }
    
    .tai1200 .tai_flex .tai_box {
        width: 30%;
        background-color: #fff;
        margin-bottom: 30px;
        cursor: pointer;
    }
    
    .tai1200 .tai_flex .tai_box .tai_img {
        width: 100%;
        height: 100px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 8px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao {
        padding: 0 8px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        background-color: rgba(41, 121, 255, .4);
        border-radius: 2px;
        color: #2979FF;
        font-size: 9px;
        font-weight: bold;
        margin: 7px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao:first-child {
        margin-left: 0;
    }
    
    .tai1200 .tai_flex .tai_box .tai_title {
        color: #000000;
        font-size: 9px;
        font-weight: bold;
        margin: 0 8px;
        margin-bottom: 8px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_time {
        color: #777981;
        font-size: 8px;
        margin: 0 8px;
        text-align: right;
        padding-bottom: 8px;
    }
}

// 手机
@media screen and (max-width:600px) {
    .taibc {
        background-color: #F6F8FB;
        padding: 15px;
    }
    
    .tai1200 {
        width: 100%;
        margin: 0 auto;
    }
    
    .tai1200 .tai_ti {
        color: #040816;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .tai1200 .tai_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
    }
    
    .tai1200 .tai_flex .tai_box {
        width: 48%;
        background-color: #fff;
        margin-bottom: 15px;
        cursor: pointer;
    }
    
    .tai1200 .tai_flex .tai_box .tai_img {
        width: 100%;
        height: 100px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 8px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao {
        padding: 0 8px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        background-color: rgba(41, 121, 255, .4);
        border-radius: 2px;
        color: #2979FF;
        font-size: 9px;
        font-weight: bold;
        margin: 7px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_box_flex .tai_biao:first-child {
        margin-left: 0;
    }
    
    .tai1200 .tai_flex .tai_box .tai_title {
        color: #000000;
        font-size: 9px;
        font-weight: bold;
        margin: 0 8px;
        margin-bottom: 8px;
    }
    
    .tai1200 .tai_flex .tai_box .tai_time {
        color: #777981;
        font-size: 8px;
        margin: 0 8px;
        text-align: right;
        padding-bottom: 8px;
    }
}