.yan1200 {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}

// 乡村这逆行研究院
.upre {
  position: relative;
}

.yan1200 .yan_box {
  width: 730px;
}

.yan1200 .yan_box .yan_font {
  color: #040816;
  font-size: 56px;
  font-weight: bold;
  margin-left: 30px;
}

.yan1200 .yan_box .yan_co {
  color: rgba(20, 18, 18, 0.5);
  font-size: 20px;
  font-weight: bold;
  margin-left: 30px;
}

.yan1200 .yan_ch {
  background-color: #2979ff;
  height: 120px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.yan1200 .yan_ch .yan_le {
  margin-left: 128px;
}

.yan1200 .yan_ch .yan_le .yan_max {
  color: #fff;
  font-size: 24px;
}

.yan1200 .yan_ch .yan_le .yan_min {
  color: #fff;
  font-size: 12px;
}

.yan1200 .yan_ch .yan_img {
  width: 40px;
  height: 40px;
}

.yan1200 .yan_right {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 365px;
  height: 515px;
  z-index: 9;
}

// 业务领域
.yan1200 .ye_ti {
  color: #040816;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.yan1200 .ye_zh {
  color: #7c7d81;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
}

.yan1200 .ye_flex {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.yan1200 .ye_flex .ye_box_img {
  width: 260px;
  height: 120px;
  margin: 30px 0;
  border-radius: 8px;
  border: 1px solid #d6e3fa;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.06);
  position: relative;
  color: #040816;
  font-size: 22px;
  padding: 20px;
}

.yan1200 .ye_flex .ye_box_hoc {
  width: 260px;
  height: 120px;
  margin: 30px 0;
  border-radius: 8px;
  background-color: #2979ff;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.06);
  position: relative;
  font-size: 22px;
  color: #fff;
  padding: 20px;
}

.yan1200 .ye_flex .yebox:nth-child(4n + 2) {
  margin: 30px 53px;
}

.yan1200 .ye_flex .yebox:nth-child(4n + 3) {
  margin-right: 53px;
}

.yan1200 .ye_flex .ye_box_hoc .ye_box_img_o,
.yan1200 .ye_flex .ye_box_img .ye_box_img_o {
  width: 80px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.te {
  text-align: center;
}

// 管理团队
.yan1200 .guan_font {
  color: #040816;
  font-size: 36px;
  font-weight: bold;
}

.yan1200 .guan_flex {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.yan1200 .guan_flex .guan_left {
  width: 260px;
  // height: 320px;
  // border: 1px solid #707070;
}

.yan1200 .guan_flex .guan_right {
  width: 910px;
  margin-left: 30px;
}

.yan1200 .guan_flex .guan_right .guan_co {
  color: #777981;
  font-size: 18px;
  margin-top: 20px;
}

// 专家智库
.yan1200 .zhuan_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.yan1200 .zhuan_flex .zhuan_box {
  width: 265px;
  background-color: #fff;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.yan1200 .zhuan_flex .zhuan_box .zhuan_box_img {
  height: 370px;
}

.yan1200 .zhuan_flex .zhuan_box .zhuan_box_w {
  min-height: 120px;
}

.yan1200 .zhuan_flex .zhuan_box .zhuan_box_w .zhuan_box_detail {
  width: 265px;
  min-height: 130px;
  overflow: hidden;
  position: absolute;
  left: -100%;
  right: 0;
  bottom: -56%;
  z-index: 9;
  font-size: 12px;
  background-color: #fff;
  padding: 5px;
  background-color: #2979ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yan1200 .zhuan_flex .zhuan_box:hover .zhuan_box_detail {
  left: 0;
  bottom: 0;
  transition: all 0.5s linear;
}

.yan1200 .zhuan_flex .zhuan_box .zhuan_box_t {
  color: #000000;
  text-align: center;
  font-size: 22px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.yan1200 .zhuan_flex .zhuan_box .zhuan_box_t_o {
  text-align: center;
  color: #b5b5b5;
  padding: 0 5px;
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

// 研究院风采
.yan1200 .yan_swiper {
  height: 220px;
  margin-top: 40px;
}

.yan1200 .yan_swiper .swiper {
  height: 220px;
  // width: 330px;
}

.yan1200 .yan_swiper .swiper-slide {
  position: relative;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

// 研究院动态
.yandongbc {
  background-color: #f0f3f8;
  padding: 20px 0;
}

.yan1200 .dong_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 40px;
}

.yan1200 .dong_flex .dong_box {
  width: 360px;
  margin-bottom: 30px;
  cursor: pointer;
  background-color: #fff;
}

.yan1200 .dong_flex .dong_box:nth-child(3n + 2) {
  margin: 0 60px;
  margin-bottom: 30px;
}

.yan1200 .dong_flex .dong_box .dong_img {
  width: 360px;
  height: 200px;
}

.yan1200 .dong_flex .dong_box .dong_box_flex {
  display: flex;
  align-items: center;
}

.yan1200 .dong_flex .dong_box .dong_box_flex .dong_box_flex_box {
  // width: 75px;
  padding: 0 15px;
  height: 30px;
  background-color: rgba(41, 121, 255, 0.4);
  font-size: 18px;
  font-weight: bold;
  color: #2979ff;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  margin: 10px 15px;
}

.yan1200 .dong_flex .dong_box .dong_va {
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-left: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.yan1200 .dong_flex .dong_box .dong_ti {
  color: #777981;
  margin-right: 15px;
  font-size: 16px;
  text-align: right;
}

.yan1200 .dong_cha {
  width: 136px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  background-color: #2979ff;
  border-radius: 24px;
  margin: 15px auto;
  cursor: pointer;
}

// 平板
@media screen and (max-width: 992px) {
  .yan1200 {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
  }

  .upre {
    position: relative;
  }

  .yan1200 .yan_box {
    width: 60%;
  }

  .yan1200 .yan_box .yan_font {
    color: #040816;
    font-size: 28px;
    font-weight: bold;
    margin-left: 15px;
  }

  .yan1200 .yan_box .yan_co {
    color: #141212;
    font-size: 10px;
    font-weight: bold;
    margin-left: 15px;
  }

  .yan1200 .yan_ch {
    background-color: #2979ff;
    height: 60px;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .yan1200 .yan_ch .yan_le {
    margin-left: 64px;
  }

  .yan1200 .yan_ch .yan_le .yan_max {
    color: #fff;
    font-size: 12px;
  }

  .yan1200 .yan_ch .yan_le .yan_min {
    color: #fff;
    font-size: 6px;
  }

  .yan1200 .yan_ch .yan_img {
    width: 20px;
    height: 20px;
  }

  .yan1200 .yan_right {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 182px;
    height: 248px;
    z-index: 9;
  }

  // 业务领域
  .yan1200 .ye_ti {
    color: #040816;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .yan1200 .ye_zh {
    color: #7c7d81;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }

  .yan1200 .ye_flex {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
  }

  .yan1200 .ye_flex .yebox:nth-child(4n + 2) {
    margin: 0;
  }

  .yan1200 .ye_flex .yebox:nth-child(4n + 3) {
    margin-right: 0;
  }

  .yan1200 .ye_flex .yebox:nth-child(3n + 2) {
    margin: 0 39px;
  }

  .yan1200 .ye_flex .ye_box_img {
    width: 30%;
    height: 60px;
    margin: 15px 0;
    border-radius: 8px;
    border: 1px solid #d6e3fa;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    color: #040816;
    font-size: 11px;
    padding: 10px;
  }

  .yan1200 .ye_flex .ye_box_hoc {
    width: 30%;
    height: 60px;
    margin: 15px 0;
    border-radius: 4px;
    background-color: #2979ff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    font-size: 11px;
    color: #fff;
    padding: 10px;
  }

  .yan1200 .ye_flex .ye_box_hoc .ye_box_img_o,
  .yan1200 .ye_flex .ye_box_img .ye_box_img_o {
    width: 40px;
    height: 30px;
    position: absolute;
    right: 5px;
    bottom: 2px;
  }

  // 管理团队
  .yan1200 .guan_font {
    color: #040816;
    font-size: 18px;
    font-weight: bold;
  }

  .yan1200 .guan_flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
  }

  .yan1200 .guan_flex .guan_left {
    width: 130px;
    height: 160px;
    // border: 1px solid #707070;
  }

  .yan1200 .guan_flex .guan_right {
    width: 100%;
    padding: 0 30px;
    margin: 0;
    margin-top: 20px;
  }

  .yan1200 .guan_flex .guan_right .guan_co {
    color: #777981;
    font-size: 9px;
  }

  // 专家智库
  .p30 {
    margin-bottom: 10px;
    padding: 0 30px;
  }

  .yan1200 .zhuan_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    flex-wrap: wrap;
  }

  .yan1200 .zhuan_flex .zhuan_box {
    width: 48%;
    background-color: #fff;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .yan1200 .zhuan_flex .zhuan_box .zhuan_box_img {
    height: 370px;
  }

  .yan1200 .zhuan_flex .zhuan_box .zhuan_box_w {
    min-height: 120px;
  }

  .yan1200 .zhuan_flex .zhuan_box .zhuan_box_w .zhuan_box_detail {
    width: 100%;
    min-height: 130px;
    overflow: hidden;
    position: absolute;
    left: -100%;
    right: 0;
    bottom: -56%;
    z-index: 9;
    font-size: 12px;
    background-color: #fff;
    padding: 5px;
    background-color: #2979ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yan1200 .zhuan_flex .zhuan_box:hover .zhuan_box_detail {
    left: 0;
    bottom: 0;
    transition: all 0.5s linear;
  }

  .yan1200 .zhuan_flex .zhuan_box .zhuan_box_t {
    color: #000000;
    text-align: center;
    font-size: 22px;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .yan1200 .zhuan_flex .zhuan_box .zhuan_box_t_o {
    text-align: center;
    color: #b5b5b5;
    padding: 0 5px;
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  // 研究院风采
  .yan1200 .yan_swiper {
    height: 220px;
    padding: 0 15px;
  }

  .yan1200 .yan_swiper .swiper {
    height: 220px;
    // width: 330px;
  }

  .yan1200 .yan_swiper .swiper-slide {
    position: relative;
    text-align: center;
    font-size: 9px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  // 研究院动态
  .yandongbc {
    background-color: #f0f3f8;
    padding: 20px 0;
  }

  .yan1200 .dong_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
  }

  .yan1200 .dong_flex .dong_box {
    width: 30%;
    margin-bottom: 15px;
    background-color: #fff;
  }

  .yan1200 .dong_flex .dong_box:nth-child(3n + 2) {
    margin: 0 30px;
    margin-bottom: 15px;
  }

  .yan1200 .dong_flex .dong_box .dong_img {
    width: 100%;
    height: 100px;
  }

  .yan1200 .dong_flex .dong_box .dong_box_flex {
    display: flex;
    align-items: center;
  }

  .yan1200 .dong_flex .dong_box .dong_box_flex .dong_box_flex_box {
    // width: 38px;
    padding: 0 8px;
    height: 15px;
    background-color: rgba(41, 121, 255, 0.4);
    font-size: 9px;
    font-weight: bold;
    color: #2979ff;
    text-align: center;
    line-height: 15px;
    border-radius: 2px;
    margin: 5px 8px;
  }

  .yan1200 .dong_flex .dong_box .dong_va {
    color: #000000;
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .yan1200 .dong_flex .dong_box .dong_ti {
    color: #777981;
    margin-right: 8px;
    font-size: 8px;
    text-align: right;
  }
}

// 手机
@media screen and (max-width: 600px) {
  .yan1200 {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

  .upre {
    position: relative;
  }

  .yan1200 .yan_box {
    width: 100%;
    margin-top: 258px;
  }

  .yan1200 .yan_box .yan_font {
    color: #040816;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }

  .yan1200 .yan_box .yan_co {
    color: #141212;
    font-size: 10px;
    font-weight: bold;
    margin: 0;
  }

  .yan1200 .yan_ch {
    background-color: #2979ff;
    height: 60px;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .yan1200 .yan_ch .yan_le {
    margin-left: 32px;
  }

  .yan1200 .yan_ch .yan_le .yan_max {
    color: #fff;
    font-size: 12px;
  }

  .yan1200 .yan_ch .yan_le .yan_min {
    color: #fff;
    font-size: 6px;
  }

  .yan1200 .yan_ch .yan_img {
    width: 20px;
    height: 20px;
  }

  .yan1200 .yan_right {
    position: absolute;
    left: 30px;
    top: 10px;
    width: 182px;
    height: 248px;
    z-index: 9;
  }

  // 业务领域
  .yan1200 .ye_ti {
    color: #040816;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .yan1200 .ye_zh {
    color: #7c7d81;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }

  .yan1200 .ye_flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .yan1200 .ye_flex .ye_box_img {
    width: 48%;
    height: 60px;
    margin: 15px 0;
    border-radius: 8px;
    border: 1px solid #d6e3fa;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    color: #040816;
    font-size: 11px;
    padding: 10px;
  }

  .yan1200 .ye_flex .ye_box_hoc {
    width: 48%;
    height: 60px;
    margin: 15px 0;
    border-radius: 4px;
    background-color: #2979ff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    font-size: 11px;
    color: #fff;
    padding: 10px;
  }

  .yan1200 .ye_flex .yebox:nth-child(3n + 2) {
    margin: 0;
  }

  .yan1200 .ye_flex .yebox:nth-child(2n + 1) {
    margin-right: 10px;
  }

  .yan1200 .ye_flex .ye_box_hoc .ye_box_img_o,
  .yan1200 .ye_flex .ye_box_img .ye_box_img_o {
    width: 40px;
    height: 30px;
    position: absolute;
    right: 5px;
    bottom: 2px;
  }

  // 专家智库
  .p30 {
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .yan1200 .zhuan_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
  }

  .yan1200 .zhuan_flex .zhuan_box {
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  // 研究院动态
  .yandongbc {
    background-color: #f0f3f8;
    padding: 15px 0;
  }

  .yan1200 .dong_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
  }

  .yan1200 .dong_flex .dong_box {
    width: 48%;
    margin-bottom: 15px;
    background-color: #fff;
  }

  .yan1200 .dong_flex .dong_box:nth-child(3n + 2) {
    margin: 0;
    margin-bottom: 15px;
  }

  .yan1200 .dong_flex .dong_box .dong_img {
    width: 100%;
    height: 100px;
  }

  .yan1200 .dong_flex .dong_box .dong_box_flex {
    display: flex;
    align-items: center;
  }

  .yan1200 .dong_flex .dong_box .dong_box_flex .dong_box_flex_box {
    // width: 38px;
    padding: 0 8px;
    height: 15px;
    background-color: rgba(41, 121, 255, 0.4);
    font-size: 9px;
    font-weight: bold;
    color: #2979ff;
    text-align: center;
    line-height: 15px;
    border-radius: 2px;
    margin: 5px 8px;
  }

  .yan1200 .dong_flex .dong_box .dong_va {
    color: #000000;
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .yan1200 .dong_flex .dong_box .dong_ti {
    color: #777981;
    margin-right: 8px;
    font-size: 8px;
    text-align: right;
  }
}
