.cun1200 {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
}

// 详情
.cun1200 .cun_left {
    width: 850px;
}

.cun1200 .cun_left .cun_title {
    color: #141212;
    font-size: 30px;
    font-weight: bold;
}

.cun1200 .cun_left .cun_flex {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.cun1200 .cun_left .cun_flex .cun_box {
    // width: 68px;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    background-color: #2979ff;
    font-size: 20px;
    margin: 15px;
}

.cun1200 .cun_left .cun_flex .cun_box:first-child {
    margin-left: 0;
}

.cun1200 .cun_left .cun_flex1 {
    display: flex;
    color: #c2c2c2;
    font-size: 16px;
    align-items: center;
    margin: 14px 0;
}

.cun1200 .cun_left .cun_flex1 .cun_flex1_left {
    margin-right: 30px;
}

.cun1200 .cun_left .cun_line {
    height: 1px;
    background-color: #E5E5E5;
}

.cun1200 .cun_left .cun_image {
    width: 100%;
    height: 430px;
    margin: 20px 0;
}

.cun1200 .cun_left .cun_con {
    color: #141212;
    font-size: 20px;
}

// 研究院
.cun1200 .cun_right {
    width: 310px;
}

.cun1200 .cun_right .cun_yan {
    color: #001529;
    font-size: 30px;
    font-weight: bold;
}

.cun1200 .cun_right .cun_line {
    height: 1px;
    background-color: #E5E5E5;
    margin: 16px 0;
}

.cun1200 .cun_right .cun_right_img {
    width: 100%;
    height: 180px;
}

.cun1200 .cun_right .cun_r_ti {
    color: #141212;
    font-size: 18px;
    margin: 6px;
}

.cun1200 .cun_right .cun_r_m {
    color: #C2C2C2;
    font-size: 16px;
}

.cun1200 .cun_right .cun_right_line {
    height: 1px;
    background-color: #EEEEEE;
    margin: 18px 0;
}

.cun1200 .cun_right .cun_right_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cun1200 .cun_right .cun_none {
    display: none;
}

.cun1200 .cun_right .cun_right_box {
    margin-bottom: 20px;
}

.cun1200 .cun_right .cun_right_box .cun_right_fen {
    display: flex;
}

.cun1200 .cun_right .cun_right_box .cun_right_img {
    width: 140px;
    height: 90px;
    margin-right: 8px;
}

.cun1200 .cun_right .cun_right_box .cun_right_two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_tti {
    color: #141212;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_time {
    color: #C2C2C2;
    font-size: 14px;
    text-align: right;
}

.cun1200 .cun_right .cun_right_box .cun_dong_line {
    height: 1px;
    background-color: #eee;
    margin-top: 18px;
}

.cun1200 .cun_right .cun_blue {
    color: #2979FF;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}

// 平板
@media screen and (max-width:992px) {
    .cun1200 {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    // 详情
    .cun1200 .cun_left {
        width: 100%;
    }

    .cun1200 .cun_left .cun_title {
        color: #141212;
        font-size: 15px;
        font-weight: bold;
    }

    .cun1200 .cun_left .cun_flex {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    .cun1200 .cun_left .cun_flex .cun_box {
        // width: 68px;
        padding: 0 8px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        color: #fff;
        border-radius: 4px;
        background-color: #2979ff;
        font-size: 10px;
        margin: 8px;
    }

    .cun1200 .cun_left .cun_flex .cun_box:first-child {
        margin-left: 0;
    }

    .cun1200 .cun_left .cun_flex1 {
        display: flex;
        color: #c2c2c2;
        font-size: 8px;
        align-items: center;
        margin: 5px 0;
    }

    .cun1200 .cun_left .cun_flex1 .cun_flex1_left {
        margin-right: 15px;
    }

    .cun1200 .cun_left .cun_line {
        height: 1px;
        background-color: #E5E5E5;
    }

    .cun1200 .cun_left .cun_image {
        width: 100%;
        height: 215px;
        margin: 10px 0;
    }

    .cun1200 .cun_left .cun_con {
        color: #141212;
        font-size: 10px;
    }

    // 研究院
    .cun1200 .cun_right {
        width: 100%;
        margin-top: 15px;
    }

    .cun1200 .cun_right .cun_yan {
        color: #001529;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }

    .cun1200 .cun_right .cun_line {
        height: 1px;
        background-color: #E5E5E5;
        margin: 8px 0;
    }

    .cun1200 .cun_right .cun_right_img {
        width: 50%;
        height: 180px;
    }

    .cun1200 .cun_right .cun_r_ti {
        color: #141212;
        font-size: 12px;
        margin: 3px;
    }

    .cun1200 .cun_right .cun_r_m {
        color: #C2C2C2;
        font-size: 10px;
    }

    .cun1200 .cun_right .cun_right_line {
        height: 1px;
        background-color: #EEEEEE;
        margin: 9px 0;
    }

    .cun1200 .cun_right .cun_right_flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

    .cun1200 .cun_right .cun_none {
        display: none;
    }

    .cun1200 .cun_right .cun_right_box {
        margin-bottom: 10px;
        width: 48%;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_fen {
        display: flex;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_img {
        width: 140px;
        height: 80px;
        margin-right: 8px;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_tti {
        color: #141212;
        font-size: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_time {
        color: #C2C2C2;
        font-size: 10px;
        text-align: right;
    }

    .cun1200 .cun_right .cun_right_box .cun_dong_line {
        height: 1px;
        background-color: #eee;
        margin-top: 9px;
    }

    .cun1200 .cun_right .cun_blue {
        color: #2979FF;
        font-size: 15px;
        text-align: center;
    }
}

// 手机
@media screen and (max-width:600px) {
    .cun1200 {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    // 详情
    .cun1200 .cun_left {
        width: 100%;
    }

    .cun1200 .cun_left .cun_title {
        color: #141212;
        font-size: 15px;
        font-weight: bold;
    }

    .cun1200 .cun_left .cun_flex {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    .cun1200 .cun_left .cun_flex .cun_box {
        // width: 68px;
        padding: 0 8px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        color: #fff;
        border-radius: 4px;
        background-color: #2979ff;
        font-size: 10px;
        margin: 8px;
    }

    .cun1200 .cun_left .cun_flex .cun_box:first-child {
        margin-left: 0;
    }

    .cun1200 .cun_left .cun_flex1 {
        display: flex;
        color: #c2c2c2;
        font-size: 8px;
        align-items: center;
        margin: 5px 0;
    }

    .cun1200 .cun_left .cun_flex1 .cun_flex1_left {
        margin-right: 15px;
    }

    .cun1200 .cun_left .cun_line {
        height: 1px;
        background-color: #E5E5E5;
    }

    .cun1200 .cun_left .cun_image {
        width: 100%;
        height: 215px;
        margin: 10px 0;
    }

    .cun1200 .cun_left .cun_con {
        color: #141212;
        font-size: 10px;
    }

    // 研究院
    .cun1200 .cun_right {
        width: 100%;
    }

    .cun1200 .cun_right .cun_yan {
        color: #001529;
        font-size: 15px;
        font-weight: bold;
    }

    .cun1200 .cun_right .cun_line {
        height: 1px;
        background-color: #E5E5E5;
        margin: 8px 0;
    }

    .cun1200 .cun_right .cun_right_img {
        width: 50%;
        height: 90px;
    }

    .cun1200 .cun_right .cun_r_ti {
        color: #141212;
        font-size: 9px;
        margin: 3px;
    }

    .cun1200 .cun_right .cun_r_m {
        color: #C2C2C2;
        font-size: 8px;
    }

    .cun1200 .cun_right .cun_right_line {
        height: 1px;
        background-color: #EEEEEE;
        margin: 9px 0;
    }

    .cun1200 .cun_right .cun_right_flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .cun1200 .cun_right .cun_none {
        display: none;
    }

    .cun1200 .cun_right .cun_right_box {
        margin-bottom: 10px;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_fen {
        display: flex;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_img {
        width: 70px;
        height: 40px;
        margin-right: 4px;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_tti {
        color: #141212;
        font-size: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .cun1200 .cun_right .cun_right_box .cun_right_two .cun_right_time {
        color: #C2C2C2;
        font-size: 7px;
        text-align: right;
    }

    .cun1200 .cun_right .cun_right_box .cun_dong_line {
        height: 1px;
        background-color: #eee;
        margin-top: 9px;
    }

    .cun1200 .cun_right .cun_blue {
        color: #2979FF;
        font-size: 10px;
        text-align: center;
    }
}