// 1200
.hea_yin {
    height: 70px;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, .08);
}

.w_1200 {
    width: 1200px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    display: flex;
    align-items: center;
}

// tabs切换
.tabs {
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-left: 58px;
    cursor: pointer;
}

.tab_act {
    color: #2979FF;
    font-weight: bold;
}

// right
.right .he {
    width: 120px;
    height: 40px;
    background-color: #2979FF;
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    color: #fff;
}

.iconge {
    display: none;
}

.mar_t {
    margin: 15px 0;
}

@media screen and (max-width:992px) {
    .w_1200 {
        width: 100%;
        padding: 0 15px;
    }

    .tabs {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        margin-left: 58px;
        cursor: pointer;
    }

    // .tabs,
    .right .he {
        display: none;
    }

    .tabs div {
        margin: 0 5px;
    }

    // .iconge {
    //     display: block;
    // }
}

@media screen and (max-width:600px) {
    .w_1200 {
        width: 100%;
        padding: 0 15px;
    }

    .tabs,
    .right .he {
        display: none;
    }

    .iconge {
        display: block;
    }
}