.zhao1200 {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

// 招募计划 背景图
.zhaobc {
  background-image: url(../../image/chaohui.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  // height: 520px;
}

.zhaobcimg {
  height: auto;
}

.zhao1200 .zhao_o {
  width: 410px;
}

.zhao1200 .zhao_o .zhao_hui {
  font-size: 56px;
  color: #141212;
  padding-top: 80px;
}

.zhao1200 .zhao_o .zhao_zhao {
  color: #2979ff;
  font-size: 56px;
}

.zhao1200 .zhao_o .zhao_line {
  width: 68px;
  height: 8px;
  background-color: #2979ff;
}

.zhao1200 .zhao_o .zhao_co {
  font-size: 20px;
  font-weight: bold;
  color: #141212;
}

.zhao1200 .zhao_o .zhao_btn {
  width: 220px;
  height: 60px;
  background-color: #2979ff;
  color: #fff;
  line-height: 60px;
  text-align: center;
  margin-top: 24px;
}

// 合作价值
.zhao1200 .he_ti {
  font-size: 36px;
  font-weight: bold;
  color: #040816;
  text-align: center;
  padding-top: 40px;
}

.zhao1200 .he_duo {
  color: #7c7d81;
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
  text-align: center;
}

.zhao1200 .he_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 24px;
}

.zhao1200 .he_flex .he_box {
  width: 400px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zhao1200 .he_flex .he_box:nth-child(3n + 2) {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  width: 398px;
}

.zhao1200 .he_flex .he_bo_line {
  border-bottom: 1px solid #e6e6e6;
  height: 229px;
}

.zhao1200 .he_flex .he_box .he_img {
  width: 64px;
  height: 64px;
}

.zhao1200 .he_flex .he_box .he_na {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.zhao1200 .he_flex .he_box .he_va {
  color: #7c7d81;
  font-size: 20px;
  width: 200px;
  text-align: center;
}

// 服务优势
.zhao1200 .fu_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}

.zhao1200 .fu_flex .fu_box {
  width: 144px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.zhao1200 .fu_flex .fu_box .fu_img {
  width: 60px;
  height: 60px;
}

.zhao1200 .fu_flex .fu_box .fu_font {
  font-size: 18px;
  color: #888888;
  margin-top: 12px;
  text-align: center;
}

// 合作流程
.zuobc {
  background-color: #f9faff;
}

.zhao1200 .zuo_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}

.zhao1200 .zuo_flex .zuo_box {
  width: 140px;
  //   height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.zhao1200 .zuo_flex .zuo_box .zuo_img {
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
}

.zhao1200 .zuo_flex .zuo_box .zuo_name {
  color: #72798c;
  font-size: 16px;
  height: 30px;
  //   line-height: 30px;
}

// 联系我们
.lianxibc {
  background-image: url(../../image/callme.png);
  height: 240px;
}

.zhao1200 .lian_ti {
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.zhao1200 .lian_flex {
  display: flex;
  align-items: center;
  margin-top: 44px;
}

.zhao1200 .lian_flex .lian_ch {
  width: 320px;
  height: 72px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 22px;
}

.paco {
  color: #999;
  font-size: 18px;
  padding: 0 11px;
}

.zhao1200 .lian_flex .lian_di {
  width: 598px;
  height: 72px;
  margin: 0 15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 22px;
}

.zhao1200 .lian_flex .lian_btn {
  width: 160px;
  height: 72px;
  border-radius: 3px;
  line-height: 72px;
  text-align: center;
  color: #fff;
  background-color: #3badff;
  font-size: 25px;
  cursor: pointer;
}

// 平板
@media screen and (max-width: 992px) {
  // 招募计划 背景图
  .zhaobc {
    background-image: url(../../image/chaohui.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 260px;
  }

  .zhao1200 {
    width: 100%;
    margin: 0 auto;
    padding: 30px;
  }

  .zhao1200 .zhao_o {
    width: 205px;
    margin: 0 30px;
  }

  .zhao1200 .zhao_o .zhao_hui {
    font-size: 28px;
    color: #141212;
    padding-top: 40px;
  }

  .zhao1200 .zhao_o .zhao_zhao {
    color: #2979ff;
    font-size: 28px;
  }

  .zhao1200 .zhao_o .zhao_line {
    width: 34px;
    height: 4px;
    background-color: #2979ff;
  }

  .zhao1200 .zhao_o .zhao_co {
    font-size: 10px;
    font-weight: bold;
    color: #141212;
  }

  .zhao1200 .zhao_o .zhao_btn {
    width: 110px;
    height: 30px;
    background-color: #2979ff;
    color: #fff;
    line-height: 30px;
    text-align: center;
    margin-top: 12px;
  }

  // 合作价值
  .zhao1200 .he_ti {
    font-size: 18px;
    padding-top: 20px;
  }

  .zhao1200 .he_duo {
    font-size: 10px;
  }

  .zhao1200 .he_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }

  .zhao1200 .he_flex .he_box {
    width: 30%;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .zhao1200 .he_flex .he_box:nth-child(3n + 2) {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    width: 30%;
  }

  .zhao1200 .he_flex .he_bo_line {
    border-bottom: 1px solid #e6e6e6;
    height: 229px;
  }

  .zhao1200 .he_flex .he_box .he_img {
    width: 32px;
    height: 32px;
  }

  .zhao1200 .he_flex .he_box .he_na {
    margin: 10px 0;
    font-size: 12px;
  }

  .zhao1200 .he_flex .he_box .he_va {
    font-size: 10px;
    width: 100px;
    text-align: center;
  }

  // 服务优势
  .zhao1200 .fu_flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0;
  }

  .zhao1200 .fu_flex .fu_box {
    width: 72px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .zhao1200 .fu_flex .fu_box .fu_img {
    width: 30px;
    height: 30px;
  }

  .zhao1200 .fu_flex .fu_box .fu_font {
    font-size: 9px;
    color: #888888;
    margin-top: 6px;
    text-align: center;
  }

  // 合作流程
  .zuobc {
    background-color: #f9faff;
  }

  .zhao1200 .zuo_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
  }

  .zhao1200 .zuo_flex .zuo_box {
    width: 70px;
    // height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .zhao1200 .zuo_flex .zuo_box .zuo_img {
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
  }

  .zhao1200 .zuo_flex .zuo_box .zuo_name {
    color: #72798c;
    font-size: 8px;
    text-align: center;
    height: 40px;
    // line-height: 40px;
  }

  // 联系我们
  .lianxibc {
    background-image: url(../../image/callme.png);
    min-height: 160px;
  }

  .zhao1200 .lian_ti {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .zhao1200 .lian_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
  }

  .zhao1200 .lian_flex .lian_ch {
    width: 160px;
    height: 72px;
    border-radius: 3px;
    display: flex;
    // align-items: center;
    background-color: #fff;
  }

  .zhao1200 .lian_flex .lian_di {
    width: 299px;
    height: 72px;
    margin: 0 7px;
    border-radius: 3px;
    display: flex;
    // align-items: center;
    background-color: #fff;
  }

  .zhao1200 .lian_flex .lian_btn {
    width: 80px;
    height: 72px;
    border-radius: 3px;
    line-height: 72px;
    text-align: center;
    color: #fff;
    background-color: #3badff;
    font-size: 18px;
  }
}

// 手机
@media screen and (max-width: 600px) {
  // 合作价值
  .zhao1200 .he_ti {
    font-size: 18px;
    padding-top: 20px;
  }

  .zhao1200 .he_duo {
    font-size: 10px;
  }

  .zhao1200 .he_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .zhao1200 .he_flex .he_box {
    width: 100%;
    height: 229px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
  }

  .zhao1200 .he_flex .he_box:nth-child(3n + 2) {
    border-left: none;
    border-right: none;
    width: 100%;
  }

  .zhao1200 .he_flex .he_bo_line {
    border-bottom: 1px solid #e6e6e6;
    height: 229px;
  }

  // 服务优势
  .zhao1200 .fu_flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0;
  }

  .zhao1200 .fu_flex .fu_box {
    width: 48%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    height: 90px;
  }

  .zhao1200 .fu_flex .fu_box .fu_img {
    width: 30px;
    height: 30px;
  }

  .zhao1200 .fu_flex .fu_box .fu_font {
    font-size: 9px;
    color: #888888;
    margin-top: 6px;
    text-align: center;
    height: 60px;
  }

  // 合作流程
  .zuobc {
    background-color: #f9faff;
  }

  .zhao1200 .zuo_flex {
    display: flex;
    // justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px 0 0 0;
  }

  .zhao1200 .zuo_flex .zuo_box {
    width: 30%;
    // min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
  }

  .zhao1200 .zuo_flex .zuo_box:nth-child(3n + 2) {
    margin: 0 15.5px;
    margin-bottom: 35px;
  }

  .zhao1200 .zuo_flex .zuo_box .zuo_img {
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
  }

  .zhao1200 .zuo_flex .zuo_box .zuo_name {
    color: #72798c;
    font-size: 8px;
    text-align: center;
    height: 40px;
  }

  // 联系我们
  .lianxibc {
    background-image: url(../../image/callme.png);
    min-height: 380px;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .zhao1200 .lian_ti {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .zhao1200 .lian_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;
  }

  .zhao1200 .lian_flex .lian_ch {
    width: 80%;
    height: 72px;
    border-radius: 3px;
    display: flex;
    // align-items: center;
    background-color: #fff;
    margin-top: 20px;
  }

  .zhao1200 .lian_flex .lian_di {
    width: 80%;
    height: 72px;
    margin: 0 7px;
    border-radius: 3px;
    display: flex;
    // align-items: center;
    background-color: #fff;
    margin-top: 20px;
  }

  .zhao1200 .lian_flex .lian_btn {
    width: 80%;
    height: 72px;
    border-radius: 3px;
    line-height: 72px;
    text-align: center;
    color: #fff;
    background-color: #3badff;
    font-size: 18px;
    margin-top: 20px;
  }
}
