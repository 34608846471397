// 介绍
.w1200 {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
}

.dino {
  display: none;
}

// 介绍
.w1200 .left1 {
  width: 610px;
}

.pt {
  padding-top: 50px;
}

// 介绍
.w1200 .left1 .fona {
  color: #040816;
  font-size: 36px;
  font-weight: bold;
}

// 介绍
.w1200 .chaohui {
  width: 460px;
  height: 360px;
}

// 介绍
.coto {
  color: #777981;
  font-size: 18px;
  margin: 30px 0;
}

// 核心优势 产品优势
.hw1200 {
  width: 1200px;
  margin: 10px auto;
}

.hw1200 .hexin {
  color: #040816;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.hw1200 .sanda {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #7c7d81;
  margin: 12px 0 20px 0;
}

// tabs
.hw1200 .santabs {
  width: 375px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #efefed;
  border-radius: 22px;
  color: #777981;
  font-size: 18px;
  margin: 0 auto;
  cursor: pointer;
}

.hw1200 .santabs .tabsact {
  width: 125px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background-color: #2979ff;
  border-radius: 22px;
  color: #fff;
}

.hw1200 .santabs .tabsnoac {
  text-align: center;
  width: 125px;
}

// 内容
.hw1200 .tabs_o {
  display: flex;
  align-items: center;
  height: 240px;
  margin: 20px 0;
}

.hw1200 .tabs_o .tabs_left {
  width: 632px;
}

.hw1200 .tabs_o .tabs_nei {
  width: 568px;
}

.hw1200 .tabs_o .tabs_left .tabs_coft {
  color: #777981;
  font-size: 18px;
  width: 390px;
}

.hw1200 .tabs_o .tabs_left .borline {
  width: 60px;
  height: 6px;
  background-color: #2979ff;
  margin: 10px 0;
}

.hw1200 .tabs_o .tabs_left .tabs_font {
  color: #000;
  font-size: 24px;
  font-weight: bold;
}

.hw1200 .tabs_o .tabs_left .tabs_san {
  display: flex;
}

.hw1200 .tabs_o .tabs_left .tabs_san .sano {
  margin: 20px 10px;
  color: #000;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.hw1200 .tabs_o .tabs_left .tabs_san .sano:first-child {
  margin-left: 0;
}

.hw1200 .tabs_o .tabs_left .tabs_san .sano .sano_im {
  width: 44px;
  height: 44px;
}

// 产品服务
.hw1200 .chan {
  color: #040816;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  padding-top: 40px;
}

.hw1200 .chansi {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #7c7d81;
  margin: 8px 0 40px 0;
}

.hw1200 .chan_si {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hw1200 .chan_si .chan_left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.hw1200 .chan_si .chan_left .chan_flex {
  display: flex;
  align-items: center;
  margin: 10px 0;
  flex: 1;
}

.hw1200 .chan_si .chan_left .chan_flex .chan_left_ai {
  width: 112px;
  height: 112px;
}

.p20 {
  padding: 35px !important;
}

.hw1200 .chan_si .chan_left .chan_flex .chan_kuan {
  width: 440px;
  min-height: 190px;
  padding: 20px;
  border-radius: 8px;
  background-image: none;
  position: relative;
  border: 1px solid rgba(210, 225, 250, 0.9);
}

.hw1200 .chan_si .chan_left .chan_flex .chan_kuan:hover {
  width: 453px;
  min-height: 190px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background-image: url(../../image/chansass.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
}

.hw1200 .chan_si .chan_left .chan_flex .chan_kuan .chan_bei {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
}

.hw1200 .chan_si .chan_left .chan_flex .chan_font {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
}

.hw1200 .chan_si .chan_left .chan_flex .chan_hui {
  color: #777981;
  font-size: 16px;
}

// 合作客户
.widbco {
  padding: 40px 0;
  background-color: #f6f8fb;
}

.hw1200 .he_qi {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.hw1200 .he_qi .qi_zhi {
  width: 340px;
  margin: 20px 0;
  height: 130px;
}

.hw1200 .he_qi .qi_zhi:nth-child(3n + 2) {
  margin: 0 60px;
}

// 联系我们
.bacimg {
  min-height: 652px;
  width: 100%;
  background: url(../../image/one.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: -8px;
}

.hw1200 .lian_me {
  font-weight: bold;
  font-size: 36px;
  color: #fff;
  text-align: center;
  padding-top: 40px;
}

.hw1200 .lian_you {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 8px;
}

.hw1200 .lian_fen {
  display: flex;
  // align-items: center;
  margin-top: 40px;
  justify-content: center;
}

.hw1200 .lian_fen .lian_left {
  width: 356px;
  color: #fff;
}

.hw1200 .lian_fen .lian_left .lian_zai {
  font-size: 20px;
  font-weight: bold;
}

.hw1200 .lian_fen .lian_left .lian_val {
  font-size: 16px;
}

.hw1200 .lian_fen .lian_left .lian_mb {
  margin-bottom: 30px;
}

.hw1200 .lian_fen .lian_right {
  width: 844px;
}

.hw1200 .lian_fen .lian_right .lian_flex {
  display: flex;
  align-items: center;
}

.hw1200 .lian_fen .lian_right .lian_flex .lian_cheng {
  width: 300px;
  height: 72px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lian_cheng_max {
  color: #999;
  font-size: 18px;
  padding: 0 11px;
}

.hw1200 .lian_fen .lian_right .lian_flex .lian_phone {
  width: 470px;
  height: 72px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
}

.hw1200 .lian_fen .lian_right .lian_text {
  min-height: 100px;
  width: 800px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 30px;
}

.hw1200 .lian_fen .lian_right .lian_ti {
  width: 464px;
  height: 73px;
  border-radius: 3px;
  background-color: #2979ff;
  line-height: 73px;
  text-align: center;
  color: #fff;
  font-size: 25px;
  margin-top: 30px;
  cursor: pointer;
}

// 平板
@media screen and (max-width: 992px) {
  // 介绍
  .w1200 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }

  // 介绍
  .w1200 .left1 {
    width: 50%;
  }

  // 介绍
  .w1200 .chaohui {
    width: 50%;
    height: 100%;
  }

  // 介绍
  .w1200 .left1 .fona {
    color: #040816;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  // 介绍
  .coto {
    color: #777981;
    font-size: 9px;
    margin: 15px 0;
  }

  // 核心优势
  .hw1200 {
    width: 100%;
    padding: 0 30px;
  }

  //核心优势
  .hw1200 .hexin {
    color: #040816;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  // 三大核心优势
  .hw1200 .sanda {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: #7c7d81;
    margin: 12px 0;
  }

  // 内容
  .hw1200 .tabs_o {
    display: flex;
    align-items: center;
    margin: 10px;
    height: 120px;
  }

  .hw1200 .tabs_o .tabs_left {
    width: 52%;
  }

  .hw1200 .tabs_o .tabs_nei {
    width: 48%;
    height: 120px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_coft {
    color: #777981;
    font-size: 9px;
    width: 100%;
  }

  .hw1200 .tabs_o .tabs_left .tabs_font {
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }

  .hw1200 .tabs_o .tabs_left .borline {
    width: 60px;
    background-color: #2979ff;
    margin: 10px 0;
    height: 6px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san {
    display: flex;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san .sano {
    margin: 5px 10px;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san .sano .sano_im {
    width: 22px;
    height: 22px;
  }

  // 产品服务
  .hw1200 .chan {
    font-size: 18px;
    padding-top: 45px;
  }

  .hw1200 .chansi {
    font-size: 10px;
    margin: 6px 0;
  }

  .hw1200 .chan_si {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .hw1200 .chan_si .chan_left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .hw1200 .chan_si .chan_left .chan_flex {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_left_ai {
    width: 56px;
    height: 56px;
    margin-right: 10px;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_kuan {
    height: auto;
    width: 330px;
    padding: 10px;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_font {
    // color: #000000;
    font-size: 12px;
    // font-weight: bold;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_hui {
    // color: #777981;
    font-size: 8px;
  }

  // 合作客户
  .widbco {
    padding: 20px 0;
  }

  .hw1200 .he_qi {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 15px;
  }

  .hw1200 .he_qi .qi_zhi {
    width: 48%;
    margin: 20px 0;
    height: 130px;
  }

  .hw1200 .he_qi .qi_zhi:nth-child(3n + 2) {
    margin: 0;
  }

  // 联系我们
  .bacimg {
    min-height: 320px;
    width: 100%;
    margin-top: -8px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .hw1200 .lian_me {
    font-size: 18px;
    padding-top: 20px;
  }

  .hw1200 .lian_you {
    font-size: 10px;
    margin-top: 4px;
  }

  .hw1200 .lian_fen {
    margin-top: 20px;
  }

  .hw1200 .lian_fen .lian_left {
    width: 178px;
  }

  .hw1200 .lian_fen .lian_left .lian_zai {
    font-size: 10px;
  }

  .hw1200 .lian_fen .lian_left .lian_val {
    font-size: 8px;
  }

  .hw1200 .lian_fen .lian_left .lian_mb {
    margin-bottom: 15px;
  }

  .hw1200 .lian_fen .lian_right {
    width: 422px;
  }

  .hw1200 .lian_fen .lian_right .lian_flex {
    display: flex;
    align-items: center;
  }

  .hw1200 .lian_fen .lian_right .lian_flex .lian_cheng {
    width: 150px;
    height: 50px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .lian_cheng_max {
    font-size: 9px;
    padding: 0 11px;
  }

  .hw1200 .lian_fen .lian_right .lian_flex .lian_phone {
    width: 235px;
    height: 50px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
  }

  .hw1200 .lian_fen .lian_right .lian_text {
    min-height: 60px;
    width: 400px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 15px;
  }

  .hw1200 .lian_fen .lian_right .lian_ti {
    width: 232px;
    height: 36px;
    border-radius: 3px;
    background-color: #2979ff;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin: 15px 0;
  }
}

// 手机
@media screen and (max-width: 600px) {
  // 介绍
  .w1200 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  // 介绍
  .w1200 .left1 {
    width: 100%;
    padding: 0 10px;
  }

  // 介绍
  .w1200 .left1 .fona {
    color: #040816;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  // 介绍
  .coto {
    color: #777981;
    font-size: 9px;
    margin: 15px 0;
  }

  // 核心优势
  .hw1200 {
    margin: 0 auto;
    width: 100%;
  }

  // tabs
  .hw1200 .santabs {
    width: 187px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #efefed;
    border-radius: 11px;
    color: #777981;
    font-size: 9px;
    margin: 0 auto;
  }

  // tabs
  .hw1200 .santabs .tabsact {
    width: 62px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background-color: #2979ff;
    border-radius: 11px;
    color: #fff;
  }

  // tabs
  .hw1200 .santabs .tabsnoac {
    text-align: center;
    width: 62px;
  }

  // 内容
  .hw1200 .tabs_o {
    display: flex;
    height: auto;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .hw1200 .tabs_o .tabs_left {
    width: 100%;
  }

  .hw1200 .tabs_o .tabs_nei {
    width: 100%;
    height: 120px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_coft {
    color: #777981;
    font-size: 9px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_font {
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }

  .hw1200 .tabs_o .tabs_left .borline {
    width: 30px;
    background-color: #2979ff;
    margin: 5px 0;
    height: 3px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san {
    display: flex;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san .sano {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    margin: 5px 0;
    margin-right: 10px;
  }

  .hw1200 .tabs_o .tabs_left .tabs_san .sano .sano_im {
    width: 22px;
    height: 22px;
  }

  // 产品服务
  .hw1200 .chan {
    font-size: 18px;
    padding-top: 20px;
  }

  .hw1200 .chansi {
    font-size: 10px;
    margin: 6px 0;
  }

  .hw1200 .chan_si {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .hw1200 .chan_si .chan_left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .hw1200 .chan_si .chan_left .chan_flex {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_left_ai {
    width: 56px;
    height: 56px;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_kuan {
    width: 90%;
    padding: 10px;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_kuan:hover {
    width: 90%;
    min-height: 190px;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    background-image: url(../../image/chansass.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_font {
    font-size: 12px;
  }

  .hw1200 .chan_si .chan_left .chan_flex .chan_hui {
    font-size: 8px;
  }

  // 合作客户
  .widbco {
    padding: 10px 0;
  }

  .hw1200 .he_qi {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: 0 15px;
  }

  .hw1200 .he_qi .qi_zhi {
    width: 100%;
    margin: 20px 0;
    height: 130px;
  }

  .hw1200 .he_qi .qi_zhi:nth-child(3n + 2) {
    margin: 20px 0;
  }

  // 联系我们
  .bacimg {
    min-height: 560px;
    padding: 0px;
    width: 100%;
    background-position: center;
    background-size: 100%;
    background-repeat: repeat-y;
  }

  .hw1200 .lian_me {
    font-size: 18px;
    padding-top: 20px;
  }

  .hw1200 .lian_you {
    font-size: 10px;
    margin-top: 4px;
  }

  .hw1200 .lian_fen {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hw1200 .lian_fen .lian_left {
    width: 100%;
    text-align: center;
  }

  .hw1200 .lian_fen .lian_left .lian_zai {
    font-size: 10px;
  }

  .hw1200 .lian_fen .lian_left .lian_val {
    font-size: 8px;
  }

  .hw1200 .lian_fen .lian_left .lian_mb {
    margin-bottom: 15px;
  }

  .hw1200 .lian_fen .lian_right {
    width: 100%;
  }

  .hw1200 .lian_fen .lian_right .lian_flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .hw1200 .lian_fen .lian_right .lian_flex .lian_cheng {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .lian_cheng_max {
    font-size: 9px;
    padding: 0 11px;
  }

  .hw1200 .lian_fen .lian_right .lian_flex .lian_phone {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px 0;
  }

  .hw1200 .lian_fen .lian_right .lian_text {
    min-height: 60px;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin-top: 0;
  }

  .hw1200 .lian_fen .lian_right .lian_ti {
    width: 100%;
    height: 36px;
    border-radius: 3px;
    background-color: #2979ff;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    padding: 0;
  }
}
