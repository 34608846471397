.dibu_bcimg {
    height: 310px;
    width: 100%;
    background-color: #01153D;
}

.di1200 {
    margin: 0 auto;
    width: 1200px;
    height: 240px;
}

.di1200 .dibu_flex {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}

// 左边
.di1200 .dibu_flex .dibu_left {
    width: 272px;
}

.di1200 .dibu_flex .dibu_left .dibu_image {
    width: 140px;
    height: 48px;
}

.di1200 .dibu_flex .dibu_left .dibu_co {
    color: #fff;
    font-size: 12px;
    margin-top: 12px;
}

// 中间
.di1200 .dibu_flex .dibu_center {
    width: 600px;
    display: flex;
    justify-content: space-between;
}

.di1200 .dibu_flex .dibu_center .dibu_center_one {
    width: 300px;
}

.di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_t {
    font-size: 20px;
    color: #f1f1f1;
}

.di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_one_co{
    font-size: 14px;
    color: #fff;
    margin: 12px 0;
    cursor: pointer;
}

// 右边
.di1200 .dibu_flex .dibu_right {
    width: 105px;
}

.di1200 .dibu_flex .dibu_right .dibu_zhong {
    color: #F1F1F1;
    font-size: 20px;
}

.di1200 .dibu_flex .dibu_right .dibu_zhongim {
    width: 105px;
    height: 105px;
    margin-top: 20px;
}

// 工商号
.dibu_gong{
    height: 70px;
    border-top: 1px solid #182B3D;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dibu_gong .dibu_left{
    font-size: 14px;
    color: #CECECE;
}

.dibu_gong .dibu_right a{
    font-size: 14px;
    color: #81E5FF;
    margin-left: 10px;
}

// 平板
@media screen and (max-width:992px) {
    .dibu_bcimg {
        height: auto;
        width: 100%;
        background-color: #01153D;
    }
    
    .di1200 {
        width: 100%;
        height: 200px;
        padding: 0 30px;
    }
    
    .di1200 .dibu_flex {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
    }
    
    // 左边
    .di1200 .dibu_flex .dibu_left {
        width: 150px;
    }
    
    .di1200 .dibu_flex .dibu_left .dibu_image {
        width: 70px;
        height: 24px;
    }
    
    .di1200 .dibu_flex .dibu_left .dibu_co {
        color: #fff;
        font-size: 6px;
        margin-top: 12px;
    }
    
    // 中间
    .di1200 .dibu_flex .dibu_center {
        width: 270px;
        display: flex;
        justify-content: space-between;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one {
        width: 150px;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_t {
        font-size: 10px;
        color: #f1f1f1;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_one_co{
        font-size: 7px;
        color: #fff;
        margin: 5px 0;
    }
    
    // 右边
    .di1200 .dibu_flex .dibu_right {
        width: 105px;
    }
    
    .di1200 .dibu_flex .dibu_right .dibu_zhong {
        color: #F1F1F1;
        font-size: 10px;
    }
    
    .di1200 .dibu_flex .dibu_right .dibu_zhongim {
        width: 105px;
        height: 105px;
        margin-top: 10px;
    }
    
    // 工商号
    .dibu_gong{
        height: 35px;
        border-top: 1px solid #182B3D;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .dibu_gong .dibu_left a{
        font-size: 7px;
        color: #CECECE;
    }
    
    .dibu_gong .dibu_right a{
        font-size: 7px;
        color: #81E5FF;
        margin-left: 5px;
    }
}

// 手机
@media screen and (max-width:600px) {
    .dibu_bcimg {
        height: auto;
        width: 100%;
        background-color: #01153D;
    }
    
    .di1200 {
        width: 100%;
        height: auto;
    }
    
    .di1200 .dibu_flex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 40px;
    }
    
    // 左边
    .di1200 .dibu_flex .dibu_left {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .di1200 .dibu_flex .dibu_left .dibu_image {
        width: 140px;
        height: 48px;
    }
    
    .di1200 .dibu_flex .dibu_left .dibu_co {
        color: #fff;
        font-size: 6px;
        margin-top: 6px;
    }
    
    // 中间
    .di1200 .dibu_flex .dibu_center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one {
        width: 300px;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_t {
        font-size: 20px;
        color: #f1f1f1;
    }
    
    .di1200 .dibu_flex .dibu_center .dibu_center_one .dibu_center_one_co{
        font-size: 14px;
        color: #fff;
        margin: 10px 0;
        cursor: pointer;
    }
    
    // 右边
    .di1200 .dibu_flex .dibu_right {
        width: 100%;
        margin-top: 20px;
    }
    
    .di1200 .dibu_flex .dibu_right .dibu_zhong {
        color: #F1F1F1;
        font-size: 20px;
    }
    
    .di1200 .dibu_flex .dibu_right .dibu_zhongim {
        width: 105px;
        height: 105px;
        margin-top: 10px;
    }
    
    // 工商号
    .dibu_gong{
        height: 70px;
        border-top: 1px solid #182B3D;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .dibu_gong .dibu_left{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .dibu_gong .dibu_left a{
        font-size: 14px;
        color: #CECECE;
        text-align: center;
    }
    
    .dibu_gong .dibu_right a{
        font-size: 14px;
        color: #81E5FF;
        margin-left: 10px;
    }
}