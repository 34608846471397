.hui_yong {
  // background: -webkit-linear-gradient(left, #FFF6F2, #FEEFEA, #FFFAF7, #feebe6, #FEF2F0, #FFFFFE);
  background-image: url(https://www.huiworker.com/uploads/20220714/24089734f7743cbe51e1242d22a7d1d3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hui_shang {
  // background: -webkit-linear-gradient(left, #2C63E2, #749AF1, #8BACF8, #6CAAF1, #6795FF, #3B71EE)
  background-image: url(https://www.huiworker.com/uploads/20220714/e84431e1480fabb05ff1542a9cac9a0f.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hui_qi {
  // background: -webkit-linear-gradient(left, #E7F8F3, #D7F8EE, #9EF6DC, #E4FFF7)
  background-image: url(https://www.huiworker.com/uploads/20220714/c81f07f7077ab57047ec73c59a43be26.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hui1200 {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: flex;
}

// 左边
.hui1200 .hui1200_left {
  width: 70%;
}

.hui1200 .hui1200_left .hui_title {
  color: #888888;
  font-size: 22px;
  margin: 40px 0 12px 0;
}

.hui1200 .hui1200_left .hui_name {
  color: #000000;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.hui1200 .hui1200_left .hui_title1 {
  color: #888888;
  font-size: 22px;
  margin: 0px 0 20px 0;
}

.hui1200 .hui1200_left .hui_val {
  color: #000000;
  font-size: 24px;
  margin-bottom: 8px;
}

.hui1200 .hui1200_left .hui_img {
  width: 140px;
  height: 140px;
  margin-bottom: 60px;
}

.hui1200 .hui1200_left .hui_title_co {
  color: #fff;
  font-size: 22px;
  margin: 20px 0;
}

.hui1200 .hui1200_left .hui_name_co {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
}

.hui1200 .hui1200_left .hui_val_co {
  color: #fff;
  font-size: 24px;
}

// 右边
.hui1200 .hui1200_right {
  display: none;
}

@media screen and (max-width: 992px) {
  .hui_yong {
    background-image: url(../../image/yoyo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui_shang {
    background-image: url(../../image/huhu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui_qi {
    background-image: url(../../image/qiqi.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui1200 {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 左边
  .hui1200 .hui1200_left {
    width: 60%;
  }

  .hui1200 .hui1200_left .hui_title {
    font-size: 11px;
    margin: 10px 0;
  }

  .hui1200 .hui1200_left .hui_name {
    font-size: 24px;
  }

  .hui1200 .hui1200_left .hui_val {
    font-size: 12px;
  }

  .hui1200 .hui1200_left .hui_img {
    width: 70px;
    height: 70px;
    margin-top: 8px;
  }

  .hui1200 .hui1200_left .hui_title1 {
    color: #888888;
    font-size: 11px;
    margin: 0px 0 20px 0;
  }

  .hui1200 .hui1200_left .hui_title_co {
    font-size: 11px;
    margin: 10px 0;
  }

  .hui1200 .hui1200_left .hui_name_co {
    font-size: 24px;
  }

  .hui1200 .hui1200_left .hui_val_co {
    font-size: 12px;
  }

  // 右边
  .hui1200 .hui1200_right {
    width: 155px;
    height: 210px;
    display: block;
  }
}

// 手机
@media screen and (max-width: 600px) {
  .hui_yong {
    background-image: url(../../image/yoyo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui_shang {
    background-image: url(../../image/huhu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui_qi {
    background-image: url(../../image/qiqi.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .hui1200 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 左边
  .hui1200 .hui1200_left {
    flex: 1;
  }

  .hui1200 .hui1200_left .hui_title {
    font-size: 11px;
    margin: 10px 0;
  }

  .hui1200 .hui1200_left .hui_name {
    font-size: 24px;
  }

  .hui1200 .hui1200_left .hui_val {
    font-size: 12px;
  }

  .hui1200 .hui1200_left .hui_img {
    width: 70px;
    height: 70px;
    margin-top: 4px;
  }

  .hui1200 .hui1200_left .hui_title_co {
    font-size: 11px;
    margin: 10px 0;
  }

  .hui1200 .hui1200_left .hui_name_co {
    font-size: 24px;
  }

  .hui1200 .hui1200_left .hui_val_co {
    font-size: 12px;
  }

  // // 右边
  // .hui1200 .hui1200_right {
  //     flex: 1;
  //     display: block;
  // }
}
